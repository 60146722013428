import React, {useEffect, useRef, useState} from 'react';
import {AdjustmentsHorizontalIcon, MagnifyingGlassIcon, PlusIcon, XMarkIcon} from '@heroicons/react/24/solid';
import '../../../assets/css/main.css';
import UserCard from './UserCard';
// import ConsultCard from './ConsultCard';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {fetchUserProfile} from '../../../actions/profileActions';
import {fetchGallupList} from '../../../actions/gallupActions';
import {useTranslation} from "react-i18next";
import Loader from "../layout/Loader";

const Main = () => {
	const { t } = useTranslation('translation', {keyPrefix: 'dashboard.main'});
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
	const profile = useSelector(state => state.profile.userProfile);
	const gallupList = useSelector(state => state.gallup.gallupList);
	const loading = useSelector(state => state.profile.loading);

	const [activeSwitch, setActiveSwitch] = useState(t('school'));
	const [userList, setUserList] = useState(gallupList);

	const [showFilter, setShowFilter] = useState(false);

	const searchInput = useRef(null);

	const [uniqueSchools, setUniqueSchools] = useState([]);
	const [uniqueClasses, setUniqueClasses] = useState([]);
	const [uniqueCompanies, setUniqueCompanies] = useState([]);
	const [uniqueProfessions, setUniqueProfessions] = useState([]);

	const [selectedSchool, setSelectedSchool] = useState('-');
	const [selectedClass, setSelectedClass] = useState('-');
	const [selectedCompany, setSelectedCompany] = useState('-');
	const [selectedProfession, setSelectedProfession] = useState('-');
	const [reverseList, setReverseList] = useState(false);

	const filterByBusiness = (shouldReverse = false) => {
		let filteredList = gallupList ? [...gallupList] : [];
		
		if (shouldReverse) {
			filteredList.reverse();
		}
	
		switch (activeSwitch) {
			case t('school'):
				filteredList = filteredList.filter(user => user.Business === false);
				setUserList(filteredList);
				break;
			case t('reviews'):
				filteredList = filteredList.filter(user => user.Business === true);
	
				if (selectedCompany !== '-') {
					filteredList = filteredList.filter(user => user.Company === selectedCompany);
				}
				if (selectedProfession !== '-') {
					filteredList = filteredList.filter(user => user.Profession === selectedProfession);
				}
	
				setUserList(filteredList);
				break;
			default:
				setUserList(filteredList);
		}
	};
	
	const filterByBusinessFromSaved = (list, shouldReverse = false) => {
		let filteredList = list ? [...list] : [];
		
		if (shouldReverse) {
			filteredList.reverse();
		}
	
		switch (activeSwitch) {
			case t('school'):
				filteredList = filteredList.filter(user => user.Business === false);
				break;
			case t('reviews'):
				filteredList = filteredList.filter(user => user.Business === true);
	
				if (selectedCompany !== '-') {
					filteredList = filteredList.filter(user => user.Company === selectedCompany);
				}
				if (selectedProfession !== '-') {
					filteredList = filteredList.filter(user => user.Profession === selectedProfession);
				}
	
				break;
			default:
				break;
		}
		setUserList(filteredList);
	};

	const toggleSwitch = (value) => {
		searchInput.current.value = '';
		resetFilters();
		setActiveSwitch(value);
	};

	const searchUsers = (enteredName) => {
		if (enteredName !== '') {
			let filteredList = userList.filter(user => user
				.Name.toLowerCase()
				.includes(enteredName.toLowerCase()));
			setUserList(filteredList);
		} else filterByBusiness();
	};

	const filterUsers = () => {
		let filteredList = gallupList;
		if (activeSwitch === t('school')) {
			if (selectedSchool === '-' && selectedClass === '-') {
				if (reverseList) setUserList(filteredList.reverse());
				filterByBusinessFromSaved();
				return;
			}
			if (selectedSchool !== '-') {
				filteredList = filteredList.filter(user => user.School === selectedSchool);
			} else filterByBusinessFromSaved();

			if (selectedClass !== '-') {
				filteredList = filteredList.filter(user => user.Grade === selectedClass);
			} else filterByBusinessFromSaved();
		} else if (activeSwitch === t('reviews')) {
			if (selectedCompany === '-' && selectedProfession === '-') {
				if (reverseList) setUserList(filteredList.reverse());
				filterByBusinessFromSaved();
				return;
			}
			if (selectedCompany !== '-') {
				filteredList = filteredList.filter(user => user.Company === selectedCompany);
			} else filterByBusinessFromSaved();

			if (selectedProfession !== '-') {
				filteredList = filteredList.filter(user => user.Profession === selectedProfession);
			} else filterByBusinessFromSaved();
		}

		setUserList(filteredList);
		filterByBusinessFromSaved(filteredList);
	};

	const resetFilters = () => {
		if (activeSwitch === t('school')) {
			setSelectedSchool('-');
			setSelectedClass('-');
		} else {
			setSelectedCompany('-');
			setSelectedProfession('-');
		}
		setReverseList(true);

		filterByBusiness();
	};

	useEffect(() => {
		if (!isLoggedIn) {
			navigate('/login');
		} else {
			dispatch(fetchUserProfile());
			dispatch(fetchGallupList());
		}
	}, [dispatch, navigate]);

	useEffect(() => {
		if (gallupList) {
			const reversedGallupList = [...gallupList].reverse();

			filterByBusinessFromSaved(reversedGallupList);

			let schools = new Set();
			let classes = new Set();
			let companies = new Set();
			let professions = new Set();

			reversedGallupList.forEach(user => {
				if (user.School !== '') schools.add(user.School);
				if (user.Grade !== '') classes.add(user.Grade);
				if (user.Company !== '') companies.add(user.Company);
				if (user.Profession !== '') professions.add(user.Profession);
			});

			setUniqueSchools(Array.from(schools));
			setUniqueClasses(Array.from(classes));
			setUniqueCompanies(Array.from(companies));
			setUniqueProfessions(Array.from(professions));
		}
	}, [gallupList]);

	useEffect(() => {
		filterByBusiness();
	}, [activeSwitch]);

	useEffect(() => {
		if (activeSwitch === t('school')) {
			if (selectedSchool === '-' && selectedClass === '-') {
				filterByBusiness();
				setShowFilter(false);
				return;
			}
			filterUsers();
		} else if (activeSwitch === t('reviews')) {
			if (selectedCompany === '-' && selectedProfession === '-') {
				filterByBusiness();
				setShowFilter(false);
				return;
			}
			filterUsers();
		}
		setShowFilter(false);
	}, [selectedSchool, selectedClass, selectedCompany, selectedProfession]);

	console.log(userList)

	if (loading) {
		return <Loader/>;
	}

	return (
		<div className={`bg-[#eaf8fc] min-h-screen`}>
			<div className="container-dashboard mx-auto flex flex-col xl:flex-row gap-4 pb-4">
				<div className="w-full xl:w-1/4 md:flex flex-col gap-4 hidden">
					<UserCard
						name={profile && profile.name}
						gallupId={null}
						email={profile && profile.email}
						phoneNumber={profile && profile.phone}
						profSchool={profile && profile.schools}
						city={profile && profile.city}
						photoURL={profile && profile.photo_url}
						mode={'main'}
					/>
				</div>
				<div className="w-full xl:w-3/4 flex flex-col gap-4">
					<div className="w-full flex flex-row justify-between gap-2 *:rounded-[10px] *:px-4 *:py-2 md:hidden">
						<div className="flex flex-row items-center gap-4 bg-white w-full">
							<MagnifyingGlassIcon className="text-gray-400 size-5"/>
							<input type="text" placeholder="Найти пользователя"
								   ref={searchInput}
								   onChange={e => searchUsers(e.target.value)}
								   className="w-full border-none outline-none md:text-[16px] md:leading-[19.36px] text-[12px] leading-[14.52px]"/>
						</div>
						<button type="button" className="flex items-center gap-2 bg-white relative"
								onClick={() => setShowFilter(!showFilter)}>
							<AdjustmentsHorizontalIcon className="size-5 text-[#474BB1]"/>
							<div
								className={`font-semibold md:text-[16px] text-[12px] md:leading-[18.8px] leading-[14.1px] text-[#707070]`}>{t('news')}
							</div>
							{showFilter && (
								<div
									onClick={(e) => {
										e.stopPropagation();
									}}
									className='cursor-default absolute top-0 right-0 bg-white lg:p-4 p-2 rounded-[10px] flex flex-col gap-3 ring-1 ring-[#CECECE]
										text-black text-[20px] leading-[23.5px]'>

									<div className={`text-[16px] leading-[18.8px] font-bold flex items-center justify-center`}>
										<div>Фильтры</div>
										<XMarkIcon className={`size-[2em] cursor-pointer absolute right-2`}
												   onClick={() => setShowFilter(false)}/>
									</div>
									<div className="flex flex-col gap-2">
										<div className={`flex flex-col gap-1`}>
											<label className={`text-[16px] leading-[18.8px] text-start`}>
												{activeSwitch === t('school') ? 'Школа' : 'Компания'}
											</label>
											<select
												className={`text-[16px] leading-[18.8px] border-[1px] border-gray-600 rounded-md outline-none shadow-none p-2`}
												value={activeSwitch === t('school') ? selectedSchool : selectedCompany}
												disabled={activeSwitch === t('school') ? selectedClass !== '-' : selectedProfession !== '-'}
												onChange={e => activeSwitch === t('school') ? setSelectedSchool(e.target.value) : setSelectedCompany(e.target.value)}>
												<option value="-">-</option>
												{(activeSwitch === t('school') ? uniqueSchools : uniqueCompanies).map((item, index) => (
													<option value={item} key={index}>{item}</option>
												))}
											</select>
										</div>

										<div className={`flex flex-col gap-1`}>
											<label className={`text-[16px] leading-[18.8px] text-start`}>
												{activeSwitch === t('school') ? 'Класс' : 'Профессия'}
											</label>
											<select
												value={activeSwitch === t('school') ? selectedClass : selectedProfession}
												className={`text-[16px] leading-[18.8px] border-[1px] border-gray-600 rounded-md outline-none shadow-none p-2`}
												disabled={activeSwitch === t('school') ? selectedSchool !== '-' : selectedCompany !== '-'}
												onChange={e => activeSwitch === t('school') ? setSelectedClass(e.target.value) : setSelectedProfession(e.target.value)}>
												<option value="-">-</option>
												{(activeSwitch === t('school') ? uniqueClasses : uniqueProfessions).map((item, index) => (
													<option value={item} key={index}>{item}</option>
												))}
											</select>
										</div>

										<div className={`flex flex-col gap-1`}>
											<label className={`text-[16px] leading-[18.8px] text-start`}>Порядок</label>
											<select
												className={`text-[16px] leading-[18.8px] border-[1px] border-gray-600 rounded-md outline-none shadow-none p-2`}
												onChange={e => setReverseList(e.target.value === 'true')}
												value={reverseList.toString()}>
													<option value={"false"}>От старых к новым</option>
													<option value={"true"}>От новых к старым</option>
												
												
											</select>
										</div>
									</div>
									<div className={`flex flex-col gap-1`}>
										<button onClick={() => {
											setShowFilter(false);
											resetFilters();
										}}
												className='bg-red-400 p-2 rounded-[10px] px-20 py-3 text-[16px] leading-[18.8px] text-white'>
											Сбросить
										</button>
									</div>
								</div>
							)}
						</button>
					</div>
					<div
						className={`flex flex-row gap-4 w-full justify-between lg:justify-start md:*:text-center *:text-start`}>
						<Link to="/new_student"
							  className='bg-[#474BB1] lg:w-fit w-full md:px-8 px-2 md:py-4 py-2 rounded-[10px] flex flex-row gap-3 items-center justify-center text-white'>
							<div
								className={`md:text-[16px] text-[12px] md:leading-[18.8px] leading-[14.1px] font-semibold`}>
								{t('addStudent')}
							</div>
							<PlusIcon className="size-[20px] min-h-[20px] min-w-[20px]"/>
						</Link>
						<Link to="/new_user"
							  className='bg-[#474BB1] lg:w-fit w-full md:px-8 px-2 md:py-4 py-2 rounded-[10px] flex flex-row gap-3 items-center justify-center text-white'>
							<div
								className={`md:text-[16px] text-[12px] md:leading-[18.8px] leading-[14.1px] font-semibold`}>
								{t('addUser')}
							</div>
							<PlusIcon className="size-[20px] min-h-[20px] min-w-[20px]"/>
						</Link>
					</div>
					<div className="md:flex hidden justify-between items-center">
						<div id="switchBlock"
							 className="flex flex-row gap-1 md:bg-white bg-[#F9F9F9] text-black p-2 rounded-[15px]
								 *:rounded-[11px] *:cursor-pointer *:px-[13px] *:py-[6px] *:transition-colors duration-[400ms]
								 md:text-[16px] md:leading-[20.8px] text-[12px] leading-[15.6px]">
							<span className={`${activeSwitch === t('school') && 'bg-[#E3F0FF] font-bold'}`}
								  onClick={() => toggleSwitch(t('school'))}>{t('school')}</span>
							<span className={`${activeSwitch === t('reviews') && 'bg-[#E3F0FF] font-bold'}`}
								  onClick={() => toggleSwitch(t('reviews'))}>{t('reviews')}</span>
						</div>
						<div className="flex flex-row gap-2 *:rounded-[10px] *:px-4 *:py-3 h-full">
							<div className="flex flex-row items-center gap-4 bg-white">
								<MagnifyingGlassIcon className="text-gray-400 size-5"/>
								<input type="text" placeholder="Найти пользователя"
									   ref={searchInput}
									   onChange={e => searchUsers(e.target.value)}
									   className="border-none outline-none md:text-[16px] md:leading-[19.36px] text-[12px] leading-[14.52px]"/>
							</div>
							<div className="flex items-center gap-2 bg-white relative cursor-pointer"
								 onClick={() => setShowFilter(!showFilter)}>
								<AdjustmentsHorizontalIcon className="size-5 text-[#474BB1]"/>
								<div className={`font-semibold text-[16px] leading-[18.8px] text-[#707070]`}>{t('filters')}</div>
								{showFilter && (
									<div
										onClick={(e) => {
											e.stopPropagation();
										}}
										className='cursor-default absolute top-0 right-0 bg-white lg:p-4 p-2 rounded-[10px] flex flex-col gap-3 ring-1 ring-[#CECECE]
										text-black text-[20px] leading-[23.5px]'>

										<div className={`text-[16px] leading-[18.8px] font-bold flex items-center justify-center`}>
											<div>Фильтры</div>
											<XMarkIcon className={`size-[2em] cursor-pointer absolute right-2`}
													   onClick={() => setShowFilter(false)}/>
										</div>
										<div className="flex flex-col gap-2">
											<div className={`flex flex-col gap-1`}>
												<label className={`text-[16px] leading-[18.8px] text-start`}>
													{activeSwitch === t('school') ? 'Школа' : 'Компания'}
												</label>
												<select
													className={`text-[16px] leading-[18.8px] border-[1px] border-gray-600 rounded-md outline-none shadow-none p-2`}
													value={activeSwitch === t('school') ? selectedSchool : selectedCompany}
													disabled={activeSwitch === t('school') ? selectedClass !== '-' : selectedProfession !== '-'}
													onChange={e => activeSwitch === t('school') ? setSelectedSchool(e.target.value) : setSelectedCompany(e.target.value)}>
													<option value="-">-</option>
													{(activeSwitch === t('school') ? uniqueSchools : uniqueCompanies).map((item, index) => (
														<option value={item} key={index}>{item}</option>
													))}
												</select>
											</div>

											<div className={`flex flex-col gap-1`}>
												<label className={`text-[16px] leading-[18.8px] text-start`}>
													{activeSwitch === t('school') ? 'Класс' : 'Профессия'}
												</label>
												<select
													value={activeSwitch === t('school') ? selectedClass : selectedProfession}
													className={`text-[16px] leading-[18.8px] border-[1px] border-gray-600 rounded-md outline-none shadow-none p-2`}
													disabled={activeSwitch === t('school') ? selectedSchool !== '-' : selectedCompany !== '-'}
													onChange={e => activeSwitch === t('school') ? setSelectedClass(e.target.value) : setSelectedProfession(e.target.value)}>
													<option value="-">-</option>
													{(activeSwitch === t('school') ? uniqueClasses : uniqueProfessions).map((item, index) => (
														<option value={item} key={index}>{item}</option>
													))}
												</select>
											</div>

											<div className={`flex flex-col gap-1`}>
												<label className={`text-[16px] leading-[18.8px] text-start`}>Порядок</label>
												<select
													className={`text-[16px] leading-[18.8px] border-[1px] border-gray-600 rounded-md outline-none shadow-none p-2`}
													onChange={e => setReverseList(e.target.value === 'true')}
													value={reverseList.toString()}>
													<option value={"false"}>От старых к новым</option>
													<option value={"true"}>От новых к старым</option>
												
												</select>
											</div>
										</div>
										<div className={`flex flex-col gap-1`}>
											<button onClick={() => {
												setShowFilter(false);
												resetFilters();
											}}
													className='bg-red-400 p-2 rounded-[10px] px-20 py-3 text-[16px] leading-[18.8px] text-white'>
												Сбросить
											</button>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="bg-white md:p-4 p-2 rounded-lg w-full flex flex-col gap-4 overflow-x-auto">
						<div id="switchBlock"
							 className="flex flex-row w-fit mx-auto md:hidden gap-1 md:bg-white bg-[#F9F9F9] text-black p-2 rounded-[15px]
								 *:rounded-[11px] *:cursor-pointer *:px-[13px] *:py-[6px] *:transition-colors duration-[400ms]
								 md:text-[16px] md:leading-[20.8px] text-[12px] leading-[15.6px]">
							<span className={`${activeSwitch === t('school') && 'bg-[#E3F0FF] font-bold'}`}
								  onClick={() => toggleSwitch(t('school'))}>{t('school')}</span>
							<span className={`${activeSwitch === t('reviews') && 'bg-[#E3F0FF] font-bold'}`}
								  onClick={() => toggleSwitch(t('reviews'))}>{t('reviews')}</span>
						</div>
						<div
							className="flex px-2 justify-between md:text-[16px] md:leading-[18.8px] text-[12px] leading-[14.1px]">
							<div className={`text-start w-[30%]`}>{t('fullname')}</div>
							<div className={`text-center w-[20%]`}>{activeSwitch === t('school') ? t('school') : t('company')}</div>
							<div className={`text-center w-[20%]`}>{activeSwitch === t('school') ? t('lesson') : t('progress')}</div>
							<div className={`text-center w-[15%]`}>{activeSwitch === t('school') ? t('classes') : t('job')}</div>
							<div className={`text-end w-[15%]`}>{t('reports')}</div>
						</div>
						<div className={`flex gap-2 ${reverseList ? ' flex-col-reverse' : ' flex-col'}`}>
							{userList.map((user, index) => (
								<div key={user._id}
									 className={`flex md:p-2 py-2 bg-[#F9FAFF] rounded-[10px] *:flex *:items-center`}>
									<div className="w-[30%] md:text-[16px] md:leading-[18.8px] text-[12px] leading-[14.1px]">
										<Link to={user.top_talants ? `/profile/${user._id}` : `/upload/${user._id}`}
											  className='flex items-center justify-start gap-[1px] md:gap-4 text-start w-full'>
											<span>{reverseList ? userList.length - index : index + 1}.</span>
											<div>
												{user.photo_url ? (
													<img src={user.photo_url} alt=""
														 className={`size-[25px] min-w-[25px] min-h-[25px] rounded-full`}/>
												) : (
													<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
														 strokeWidth={1.5} stroke="currentColor" className="size-[25px]">
														<path strokeLinecap="round" strokeLinejoin="round"
															  d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
													</svg>
												)}

											</div>
											<span>{user.Name}</span>
										</Link>
									</div>
									<div className="w-[20%] justify-center">
										<div
											className='bg-[#DCFDE4] rounded-[5px] px-[8px] py-[5px] w-fit
											md:text-[16px] md:leading-[18.8px] text-[10px] leading-[11.75px]'>{user.Business ? user.Company : user.School}</div>
									</div>
									<div
										className="w-[20%] justify-center md:flex-row flex-col md:gap-2 gap-1 md:text-[16px] md:leading-[18.8px] text-[10px] leading-[11.75px]">
										<div className="w-full bg-[#D9D9D9] rounded-full max-w-[32px] md:max-w-[164px]">
											<div className={`bg-green-500 h-[4px] rounded-full`}
												 style={user.Business ? {width: `${user.progress / 8 * 100}%`} : {width: `${user.progress / 8 * 100}%`}}></div>
										</div>
										<div>{`${user.progress}${user.Business ? '/8' : '/8'}`}</div>
									</div>
									<div
										className="w-[15%] justify-center md:text-[16px] md:leading-[18.8px] text-[12px] leading-[14.1px] font-[600]">
										<div>{user.Business ? user.Profession : user.Grade}</div>
									</div>
									<div
										className="w-[15%] justify-end gap-1 md:text-[16px] md:leading-[20.8px] text-[12px] leading-[15.6px]">
										<span>{user.report_count}</span>
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
											 stroke="currentColor" className="size-[21px] text-[#34EBE5]">
											<path strokeLinecap="round" strokeLinejoin="round"
												  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"/>
										</svg>
									</div>
								</div>
							))}
							{userList.length === 0 && (
								<div>{t('noResults')}</div>
							)}
						</div>
					</div>
					{/* <div className={`md:hidden`}><ConsultCard mode={'main'}/></div> */}
				</div>
			</div>
		</div>
	);
}

export default Main;
