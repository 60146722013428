import axios from "axios";
import {API_URL} from "./constants";
import axiosInstance from "../interceptor/Interceptor";

const upload = (uploadData) => {
	for (var pair of uploadData.entries()) {
	}
	return axiosInstance
		.post(API_URL + `Gallup/${uploadData.get('userId')}/pdf`, uploadData, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("access_token")}`,
				"Content-Type": "multipart/form-data"
			}
		})
		.then((response) => {
			return response.data;
		});
};

const getAssessmentKey = (pdfIf) => {
	return axiosInstance
		.get(API_URL + `Gallup/create/assessment-key?pdf_id=${pdfIf}`, {
			headers: {Authorization: `Bearer ${localStorage.getItem("access_token")}`},
		})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
}

export default {
	upload,
	getAssessmentKey
};
