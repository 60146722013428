import UploadDataService from "../services/uploadData.service";
import {
	UPLOAD_SUCCESS,
	UPLOAD_FAILURE,
} from "./actionTypes/uploadUserDataActionTypes";

export const uploadUserData = (uploadData) => (dispatch) => {
	return UploadDataService.upload(uploadData).then(
		(data) => {
			dispatch({
				type: UPLOAD_SUCCESS,
				payload: {user: data},
			});

			return Promise.resolve(data);
		},
		(error) => {
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();

			dispatch({
				type: UPLOAD_FAILURE,
				error: message
			});

			return Promise.reject();
		}
	);
};


export const getAssessmentKey = (pdfId) => (dispatch) => {
	return UploadDataService.getAssessmentKey(pdfId).then(
		(data) => {
			return Promise.resolve(data);
		},
		(error) => {
			return Promise.reject(error);
		}
	);
}